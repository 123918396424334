import styles from './style.module.css';

function Copyright() {
	return (
		<div className={styles['container']}>
			<p className="text-title">&copy;&nbsp;{new Date().getFullYear()}&nbsp;David James Udoh</p>
			<p>Christ in me, the hope of glory!</p>
		</div>
	);
}

export default Copyright;
