import axios from 'axios';
import { routes } from 'services';

const baseURL = process.env.REACT_APP_BASE_URL;

const headers = {
	'Content-Type': 'application/json',
	'Access-Control-Allow-Headers': '*',
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Credentials': true,
};

const instance = axios.create({ headers, baseURL });

const apis = {
	canvas: {
		create: async (body) => {
			return await instance.post(routes.canvas.url(), { body });
		},
	},
};

export default apis;
