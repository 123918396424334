import styles from './style.module.css';
import { Copyright, Cuboid, LargeMobileButton } from 'components';
import { About, Archive, Clients, Others, Work, Writing } from './mixins';
import MobileModal from 'components/mobileModal/mobileModal';

function Home() {
	return (
		<div className="container-align"  style={{overflow:'hidden'}}>
			<div className={styles['con-section']}>
				<div className={`${styles['section-a']} section`}>
					<div>
						<About />
					</div>
					<div className="d-lg-none">
						<LargeMobileButton />
					</div>
					<div className="d-lg-none">
						<Work />
					</div>
					<div>
						<Archive />
					</div>
					<div className="d-lg-none">
						<Writing />
					</div>
					<div>
						<Others />
					</div>
				</div>

				<div className="section-b section">
					<div className="d-none d-lg-block">
						<Work />
					</div>
					<div className="d-none d-lg-block">
						<Writing />
					</div>
					<div>
						<Clients />
					</div>
				</div>

				<div className={`${styles['section-c']} section d-none d-lg-block`}>
					<Cuboid />
				</div>

				<MobileModal>
					<Cuboid />
				</MobileModal>
			</div>

			<div className={styles['con-copy']}>
				<Copyright />
			</div>
		</div>
	);
}

export default Home;
