import styles from '../style.module.css';

function Clients() {
	return (
		<div>
			<h2 className="text-title">dream projects & clients</h2>
			<div className={`${styles['text-context']} text-context`}>
				<p>
					-&nbsp;New balance: Sneakers
					<br />
					-&nbsp;Football (Campaign, Jersey, Messi)
					<br />
					-&nbsp;Street art
					<br />
					-&nbsp;History and cultural
					<br />
					-&nbsp;Jewellery
					<br />
				</p>
			</div>
		</div>
	);
}

export default Clients;
