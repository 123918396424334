import styles from './style.module.css';
import { useModalContext } from 'context/ModalContext';

function LargeMobileButton() {
	const { toggleModal } = useModalContext();
	return (
		<div className={styles['large-mobile-button']} onClick={toggleModal}>
			<p>write a note to David :)</p>
		</div>
	);
}

function MediumButton({ buttonContent, disabled, onclick }) {
	return (
		<button className={[styles['medium-mobile-button'], styles[disabled && 'disabled']].join(' ')} onClick={onclick}>
			{buttonContent}
		</button>
	);
}

export { LargeMobileButton, MediumButton };
