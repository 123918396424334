import styles from '../style.module.css';

function Writing() {
	return (
		<div>
			<h2 className="text-title">writing</h2>
			<div className={`${styles['text-context']} text-context`}>
				<p>
					-&nbsp;
					<a href="https://medium.com/@davidjamesudoh/safe-journey-o-5f5614418061">Safe Journey o!</a>
				</p>
			</div>
		</div>
	);
}

export default Writing;
