import styles from '../style.module.css';

function About() {
	return (
		<div>
			<h2 className="text-title">about</h2>
			<div className={`${styles['text-context']} text-context`}>
				<p>My name is David James Udoh. I am a Designer and Art director currently based in Lagos, Nigeria.</p>
				<p>
					I currently lead the creative effort at&nbsp;
					<a href="https://www.instagram.com/itsthehuddle">The Huddle</a>, a platform that spotlights incredible work from African creatives.
				</p>
				<p>
					I’ve worked with several brands which include&nbsp;
					<a href="https://fonts.google.com/?query=david+udoh">Google Font</a>
					,&nbsp;
					<a href="https://www.octafx.com/">Octafx</a>
					,&nbsp;
					<a href="https://www.lapa.ninja/post/investbamboo/">Bamboo</a>
					,&nbsp;and <a href="https://quidax.com">Quidax</a>
					,&nbsp; to name a few.
				</p>
				<p>I’ve worked across various design disciplines, focusing on branding and advertising. Driven by the admiration for the beauty of everyday letterforms, I now designs fonts.</p>
				<p>In my free time, I enjoy playing and watching sports, traveling, taking candid photos, vibing to music, making cool video edits and curating vernacular designs.</p>
				<p className={styles['last-child']}>
					Want to work together or just say hi? You can contact me <a href="mailto:davidjamesudoh@gmail.com">here</a>.
				</p>
			</div>
		</div>
	);
}

export default About;
