import styles from '../style.module.css';

function Archive() {
	return (
		<div>
			<h2 className="text-title">archive</h2>
			<div className={`${styles['text-context']} text-context`}>
				<div>
					-&nbsp;
					<a href="https://www.instagram.com/caveatemp.tor/">Caveat Emptor</a>
				</div>
				<div>
					-&nbsp;
					<a href="https://www.instagram.com/open.en.close/">Open and Close</a>
				</div>
			</div>
		</div>
	);
}

export default Archive;
