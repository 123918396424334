import styles from '../style.module.css';

function Others() {
	return (
		<div>
			<h2 className="text-title">others</h2>
			<div className={`${styles['text-context']} text-context`}>
				<p>
					-&nbsp;Photographs
					<br />
					-&nbsp;Journal
					<br />
					-&nbsp;Bookmarks
					<br />
				</p>
			</div>
		</div>
	);
}

export default Others;
