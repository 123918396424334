import { createContext, useState, useContext, useEffect } from 'react';
const ModalContext = createContext();

export const ModalContextProvider = ({ children }) => {
	const [openModal, setOpenModal] = useState(false);

	const toggleModal = () => {
		setOpenModal(!openModal);
	};

	// Effect to set body overflow
	useEffect(() => {
		if (openModal) {
			document.body.style.overflow = 'hidden';
			document.body.style.height = '100vh';
		} else {
			document.body.style.overflow = 'auto';
			document.body.style.height = 'auto';
		}
		return () => {
			document.body.style.overflow = 'auto';
			document.body.style.height = 'auto';
		};
	}, [openModal]);
	return <ModalContext.Provider value={{ toggleModal, openModal }}>{children}</ModalContext.Provider>;
};

export const useModalContext = () => {
	return useContext(ModalContext);
};
