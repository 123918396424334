import { useRef } from 'react';
import styles from '../style.module.css';

function Sabi() {
	const topRef = useRef();
	const scrollTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	return (
		<div className="container-align" id="top" ref={topRef}>
			<div className={styles['con-section']}>
				<div className={styles['section-a']}>
					<div>
						<h2 className="text-title">project</h2>
						<div className="text-context">
							<p>
								<a href="https://fonts.google.com/specimen/Danfo?query=david+udoh">- Danfo</a>
							</p>
							<p>
								<a href="https://fonts.google.com/specimen/Tac+One?query=david+udoh">- Tac</a>
							</p>
							<p>
								<a href="https://dixtrict26.ng/browse/David%20Udoh%20Merch%20Collab">- Safe Journey</a>
							</p>
							<p>
								<a href="https://www.instagram.com/itsthehuddle">- The Huddle</a>
							</p>
							<p>
								<a href="/projects?part=one">- Octafx 2022 Campaign</a>
							</p>
							<p>
								<a href="https://www.lapa.ninja/post/investbamboo/">- Bamboo</a>
							</p>
							<p>
								<a href="/projects?part=two">- Sabi</a>
							</p>
							<p>
								<a href="/projects">- Juicyway - WIP</a>
							</p>
						</div>
					</div>
					<div>
						<h2 className="text-title">SABI BRAND IDENTITY | 2021</h2>
						<div className="text-context">
							<p>
								In collaboration with District 26, a Nigerian streetwear brand, we launched the 'Safe Journey' merch collection in December 2023. This project goes beyond just creating fashion items; it is also about preserving a culture that holds immense
								significance for many Nigerians. My passion for Nigerian design is unwavering, and creating something that captures and celebrates this part of our unique culture makes me happy.
							</p>
						</div>
					</div>

					<div>
						<h2 className="text-title" style={{ opacity: 0 }}>
							...
						</h2>
						<div className="text-context">
							<p>
								The visual direction is a direct expression of the many signs, inscriptions, and tone of voice found on these vehicles that travelled on the road with us. It is a distinctly Nigerian-centric language, with the signs expressing good wishes,
								mantras, and religious prayers for a safe journey ahead.
							</p>
						</div>
					</div>

					<div>
						<h2 className="text-title">my role</h2>
						<div className="text-context">
							<p>- Art Direction</p>
							<p>- Graphic Design</p>
						</div>
					</div>
				</div>

				<div className={styles['section-b']}>
					<div>
						<h2 className="text-title">navigation</h2>
						<div className="text-context">
							<p>
								<a href="/">- Homepage</a>
							</p>
						</div>
					</div>

					<div>
						<img src="/assets/images/040fc7ceb819b641f02aed24d1d20ccd.jpeg" alt="section-b-image" />
					</div>
				</div>

				<div className={styles['section-c']}>
					<div>
						<img src="/assets/images/040fc7ceb819b641f02aed24d1d20ccd.jpeg" alt="section-c1-image" />
					</div>
				</div>

				<div className={styles['section-d']}>
					<div></div>
					<div></div>
					<div className="text-context">
						<p>
							In collaboration with District 26, a Nigerian streetwear brand, we launched the 'Safe Journey' merch collection in December 2023. This project goes beyond just creating fashion items; it is also about preserving a culture that holds immense
							significance for many Nigerians. My passion for Nigerian design is unwavering, and creating something that captures and celebrates this part of our unique culture makes me happy.{' '}
						</p>
					</div>
					<div></div>
				</div>

				<div className={styles['section-e']}>
					<div></div>
					<div>
						<img src="/assets/images/040fc7ceb819b641f02aed24d1d20ccd.jpeg" alt="section-e1-image" />
					</div>
					<div>
						<img src="/assets/images/040fc7ceb819b641f02aed24d1d20ccd.jpeg" alt="section-e2-image" />
					</div>
				</div>

				<div className={styles['section-c']}>
					<div>
						<img src="/assets/images/040fc7ceb819b641f02aed24d1d20ccd.jpeg" alt="section-c2-image" />
					</div>
				</div>
				<div className={styles['section-f']}>
					<div>
						<img src="/assets/images/040fc7ceb819b641f02aed24d1d20ccd.jpeg" alt="section-c2-image" />
					</div>
					<div></div>
				</div>
				<div className={styles['section-g']}>
					<div className="text-context">
						<p>
							In collaboration with District 26, a Nigerian streetwear brand, we launched the 'Safe Journey' merch collection in December 2023. This project goes beyond just creating fashion items; it is also about preserving a culture that holds immense
							significance for many Nigerians. My passion for Nigerian design is unwavering, and creating something that captures and celebrates this part of our unique culture makes me happy. In collaboration with District 26, a Nigerian streetwear brand, we
							launched the 'Safe Journey' merch collection in December 2023. This project goes beyond just creating fashion items; it is also about preserving a culture that holds immense significance for many Nigerians. My passion for Nigerian design is
							unwavering, and creating something that captures and celebrates this part of our unique culture makes me happy. In collaboration with District 26, a Nigerian streetwear brand, we launched the 'Safe Journey' merch collection in December 2023. This
							project goes beyond just creating fashion items; it is also about preserving a culture that holds immense significance for many Nigerians. My passion for Nigerian design is unwavering, and creating something that captures and celebrates this part
							of our unique culture makes me happy.
						</p>
					</div>

					<div>
						<img src="/assets/images/001.jpg" alt="section-c2-image" />
					</div>
				</div>
				<div className={styles['section-h']}>
					<div>
						<img src="/assets/images/040fc7ceb819b641f02aed24d1d20ccd.jpeg" alt="section-e1-image" />
					</div>
					<div>
						<img src="/assets/images/040fc7ceb819b641f02aed24d1d20ccd.jpeg" alt="section-e2-image" />
					</div>
				</div>

				<div className="nav-footer flex-between">
					<div className="text-context">
						<div className="non-link" onClick={scrollTop}>
							Back to top
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Sabi;
