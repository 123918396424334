import styles from './style.module.css';
import { useModalContext } from 'context/ModalContext';
import { CancelSvg } from 'svg';

const MobileModal = ({ children }) => {
	const { openModal, toggleModal } = useModalContext();
	return (
		<div className={styles['modal']} style={{ display: openModal ? 'block' : 'none' }}>
			<div className={styles['modal-dialog']}>
				<div className="my-1 ">
					<button className={styles['cancel-button']} onClick={toggleModal}>
						<CancelSvg />
					</button>
				</div>
				{children}
			</div>
		</div>
	);
};

export default MobileModal;
