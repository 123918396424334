import styles from '../style.module.css';

function Work() {
	return (
		<div>
			<h2 className="text-title">selected work</h2>
			<div className={`${styles['text-context']} text-context`}>
				<p>
					-&nbsp;
					<a href="https://fonts.google.com/specimen/Danfo?query=david+udoh">Danfo</a>
					&nbsp;(Type Design, Graphic Design)
				</p>
				<p>
					-&nbsp;
					<a href="https://fonts.google.com/specimen/Tac+One?query=david+udoh">Tac</a>
					&nbsp;(Type Design, Graphic Design)
				</p>
				<p>
					-&nbsp;
					<a href="https://dixtrict26.ng/browse/David%20Udoh%20Merch%20Collab">Safe Journey</a>
					&nbsp;(Art direction, Graphic Design)
				</p>
				<p>
					-&nbsp;
					<a href="https://www.instagram.com/itsthehuddle">The Huddle</a>
					&nbsp;(Creative Direction, Graphic Design)
				</p>
				<p>-&nbsp;Octafx 2022 Campaign (Art Direction, Graphic Design)</p>
				<p>
					-&nbsp;
					<a href="https://www.lapa.ninja/post/investbamboo/">Bamboo</a>
					&nbsp;
				</p>
				<p>-&nbsp;Sabi (Creative Direction, Brand Design, Motion Design)</p>
				<p>-&nbsp;Juicyway - WIP (Creative Direction, Brand Design, 3d Illustration, Motion Design)</p>
			</div>
		</div>
	);
}

export default Work;
