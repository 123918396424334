export const TextSvg = () => {
	return (
		<svg className="text" width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0.0390625 7.93164L2.3584 1.00781C2.37305 0.973633 2.39258 0.958984 2.42188 0.958984H4.01855C4.04297 0.958984 4.06738 0.973633 4.08203 1.00781L6.3916 7.93164C6.40625 7.97559 6.38672 8 6.33789 8H5.19043C5.16602 8 5.14648 7.98535 5.13184 7.95117L4.70215 6.59863C4.6875 6.56445 4.66309 6.5498 4.62891 6.5498H1.80176C1.76758 6.5498 1.74316 6.56445 1.72852 6.59863L1.29883 7.95117C1.29395 7.98535 1.27441 8 1.24023 8H0.0878906C0.0439453 8 0.0244141 7.97559 0.0390625 7.93164ZM3.16895 2.10156L2.10938 5.40234C2.09473 5.43652 2.10938 5.45117 2.14844 5.45117H4.29199C4.32617 5.45117 4.33594 5.43652 4.32129 5.40234L3.27148 2.10156C3.24707 2.04785 3.19336 2.04297 3.16895 2.10156ZM6.63574 6.49121C6.63574 5.62207 7.36328 4.87012 8.82812 4.87012H9.81934C10.0195 4.87012 10.0684 4.75293 10.0684 4.65039C10.0684 4.43555 9.99512 3.94238 9.01855 3.94238C8.2666 3.94238 8.03223 4.25 8.00781 4.60156C8.00781 4.63574 7.99316 4.65039 7.95898 4.65039H6.86523C6.83105 4.65039 6.81641 4.63574 6.81641 4.60156C6.81641 3.4248 7.84668 2.83887 9.01855 2.83887C9.71191 2.83887 10.2588 3 10.6641 3.31738C11.0645 3.63965 11.2646 4.11328 11.2646 4.73828V6.68164C11.2646 6.88184 11.4111 6.99902 11.626 6.99902H11.7773C11.8115 6.99902 11.8262 7.01367 11.8262 7.04785V7.95117C11.8262 7.98535 11.8115 8 11.7773 8H11.2646C10.7422 8 10.4688 7.7998 10.2881 7.52148C10.2588 7.4873 10.2295 7.4873 10.1953 7.52148C10.0635 7.65332 9.86816 7.78516 9.60938 7.90723C9.35059 8.03418 9.01855 8.09766 8.61816 8.09766C7.25586 8.09766 6.63574 7.39453 6.63574 6.49121ZM7.83691 6.49121C7.83691 6.70605 8.00293 6.99902 8.61816 6.99902C9.65332 6.99902 10.0684 6.41797 10.0684 5.91992C10.0684 5.88574 10.0488 5.87109 10.0146 5.87109H8.87695C7.9834 5.87109 7.83691 6.21777 7.83691 6.49121Z"
				fill="black"
			/>
		</svg>
	);
};

export const PencilSvg = () => {
	return (
		<svg className="pencil" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.2756 11.0583L13.271 11.0415L12.5558 8.37278C12.5471 8.342 12.5308 8.313 12.5047 8.28444L5.08992 0.869088C4.50785 0.289399 3.7445 0 2.98174 0C2.21898 0 1.4528 0.291034 0.871329 0.871319C0.290451 1.4516 1.23978e-05 2.21674 1.23978e-05 2.98173C1.23978e-05 3.74047 0.287031 4.49966 0.859137 5.0789L0.871329 5.09229L8.28088 12.5018C8.30646 12.5292 8.35182 12.553 8.38722 12.56L13.7218 13.989C13.8379 14.0204 13.9582 13.9506 13.989 13.8345C13.9994 13.7955 13.9988 13.7567 13.989 13.7212L13.2756 11.0583ZM12.0371 8.43479L10.3908 10.0811L3.29092 2.98173L4.93778 1.33486L12.0371 8.43479ZM1.33487 4.93762L2.98174 3.29076L10.0811 10.3907L8.4348 12.037L1.33487 4.93762ZM12.8185 11.0407L11.0409 12.8183L8.85715 12.2333L12.2334 8.85699L12.8185 11.0407Z"
				fill="white"
			/>
		</svg>
	);
};

export const CancelSvg = () => {
	return (
		<svg className="cancel-icon" stroke="currentColor" fill="none" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z"
				fill="currentColor"></path>
		</svg>
	);
};
