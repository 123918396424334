import { useSearchParams } from 'react-router-dom';
import OctaFX from './one/OctaFX';
import Sabi from './two/Sabi';

function Projects() {
	const [query] = useSearchParams();

	const pageMap = {
		OCTAFX: 'one',
		SABI: 'two',
		TAG: 'part',
	};

	return (
		<div>
			{query.get(pageMap.TAG) === pageMap.OCTAFX && <OctaFX />}
			{query.get(pageMap.TAG) === pageMap.SABI && <Sabi />}
			{(!query.get(pageMap.TAG) || (query.get(pageMap.TAG) !== pageMap.OCTAFX && query.get(pageMap.TAG) !== pageMap.SABI)) && <OctaFX />}
		</div>
	);
}

export default Projects;
