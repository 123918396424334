import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './style.module.css';
import { useModalContext } from 'context/ModalContext';
import { classNames, MediumButton } from 'components';
import { PencilSvg, TextSvg } from 'svg';
import { apis } from 'services';

function Cuboid() {
	const { openModal } = useModalContext();
	const colors = ['black', 'blue', 'yellow', 'red', 'pink', 'green'];
	const pencilWidth = [2, 4, 6, 8, 10, 12];

	const [openColorPallet, setOpenColorPallet] = useState(true);
	const [openPencilWidth, setOpenPencilWidth] = useState(true);
	const [currentPencil, setCurrentPencil] = useState(2);

	const canvasRef = useRef();
	const [context, setContext] = useState(null);
	const [drawing, setDrawing] = useState(false);
	const [drawingAction, setDrawingAction] = useState([]);
	const [currentColor, setCurrentColor] = useState('black');
	const [currentPath, setCurrentPath] = useState([]);
	const [currentStyle, setCurrentStyle] = useState({ color: 'black', lineWidth: 3 });
	const [text, setText] = useState('');
	const textareaRef = useRef(null);
	const [textColor, setTextColor] = useState('black');
	const [isCanvas, setCanavs] = useState(true);
	const [slidePosition, setSlidePosition] = useState('');
	const [active, setActive] = useState(false);

	const reDrawPreviousData = useCallback(
		(ctx) => {
			drawingAction.forEach(({ path, style, text, position }) => {
				if (path && path.length > 0) {
					ctx.beginPath();
					ctx.strokeStyle = style.color || 'black';
					ctx.lineWidth = style.lineWidth || 1;
					ctx.moveTo(path[0].x, path[0].y);
					path.forEach((point) => {
						if (point && point.x !== undefined && point.y !== undefined) {
							ctx.lineTo(point.x, point.y);
						}
					});
					ctx.stroke();
				}
				if (text && position) {
					ctx.font = style.font || '20px Arial';
					ctx.fillStyle = style.color || 'black';
					ctx.fillText(text, position.x, position.y);
				}
			});
		},
		[drawingAction]
	);

	useEffect(() => {
		if (textareaRef.current) {
			textareaRef.current.style.height = '';
			textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
		}
	}, [text]);

	const handleTextChange = (e) => {
		setText(e.target.value);
	};

	useEffect(() => {
		if (canvasRef.current) {
			const canvas = canvasRef.current;
			canvas.width = 800;
			canvas.height = 800;
			canvas.style.cursor = 'crosshair';
			const ctx = canvas.getContext('2d');
			setContext(ctx);
			reDrawPreviousData(ctx);
		}
	}, [reDrawPreviousData]);

	const startDrawing = (e) => {
		e.preventDefault();
		e.target.setPointerCapture(e.pointerId);
		if (context) {
			setOpenColorPallet(true);
			setOpenPencilWidth(true);
			const { offsetX, offsetY } = getEventCoordinates(e);
			context.beginPath();
			context.moveTo(offsetX, offsetY);
			setDrawing(true);
		}
	};
	const draw = (e) => {
		e.preventDefault();
		if (!drawing) return;
		if (context) {
			const { offsetX, offsetY } = getEventCoordinates(e);
			context.strokeStyle = currentStyle.color;
			context.lineWidth = currentStyle.lineWidth;
			context.lineTo(offsetX, offsetY);
			context.stroke();
			setCurrentPath([...currentPath, { x: offsetX, y: offsetY }]);
		}
	};

	const endDrawing = (e) => {
		e.preventDefault();
		if (drawing) {
			setDrawing(false);
			e.target.releasePointerCapture(e.pointerId); // Release the pointer capture
			if (currentPath.length > 0) {
				setDrawingAction([...drawingAction, { path: currentPath, style: currentStyle }]);
			}
			setCurrentPath([]);
		}
	};

	const handleSubmit = () => {
		const canvas = canvasRef.current.toDataURL('image/png');

		if (drawingAction.length > 0 || text) {
			setActive(true);
			apis.canvas.create(isCanvas ? canvas : text).then(handleSuccess).catch(handleError);
		}
	};

	const handleSuccess = () => {
		clearCanvas();
		setActive(false);
	};

	const handleError = (response) => {
		setActive(false);
		console.error(response);
	};

	const changeColor = (color) => {
		setCurrentColor(color);
		setCurrentStyle({ ...currentStyle, color });
	};

	const changeWidth = (width) => {
		setCurrentStyle({ ...currentStyle, lineWidth: width });
	};

	const clearDrawing = () => {
		setDrawingAction([]);
		setCurrentPath([]);
		const newContext = canvasRef.current.getContext('2d');
		newContext.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
	};

	const clearCanvas = () => {
		clearDrawing();
		setText('');
	};

	const activateLeft = () => {
		if (!active) {
			setSlidePosition('left');
			setCanavs(true);
			clearCanvas();
		}
	};

	const activateRight = () => {
		if (!active) {
			setSlidePosition('right');
			setCanavs(false);
			clearCanvas();
		}
	};

	const handleColorPallet = (color) => {
		setCurrentColor(color);
		setOpenPencilWidth(true);
		setOpenColorPallet(!openColorPallet);
		changeColor(color);
		console.log(color);
		setTextColor(color);
	};
	const handlePencilWidth = (index) => {
		setOpenPencilWidth(!openPencilWidth);
		setOpenColorPallet(true);
		setCurrentPencil(index);
		changeWidth(index);
		console.log(index);
	};

	const getEventCoordinates = (e) => {
		let offsetX, offsetY;
		if (e.nativeEvent instanceof PointerEvent) {
			const rect = e.target.getBoundingClientRect();
			offsetX = e.nativeEvent.clientX - rect.left;
			offsetY = e.nativeEvent.clientY - rect.top;
		} else {
			offsetX = e.nativeEvent.offsetX;
			offsetY = e.nativeEvent.offsetY;
		}
		return { offsetX, offsetY };
	};

	return (
		<>
			<div className={styles['cuboid']} style={{ height: openModal && '100%' }}>
				<div className={`${styles['first-rectangle']}`}></div>
				<div className={`${styles['second-rectangle']}`}>
					<div className={`${styles['canvas-holder']}`}>
						<div className={`${styles['canvas-box']}`}>
							<canvas ref={canvasRef} onPointerDown={startDrawing} onPointerMove={draw} onPointerUp={endDrawing} className="border_canvas" style={{ display: isCanvas ? 'block' : 'none' }} />

							<div className={`${styles['textarea-box']}`} style={{ display: `${isCanvas ? 'none' : 'flex'}` }}>
								<textarea ref={textareaRef} value={text} onChange={handleTextChange} placeholder="Here you go! please keep it positive :)" style={{ color: `${textColor}` }}></textarea>
							</div>
						</div>
						<div className={styles['canvas-actions']}>
							<div className="flex ">
								<div className={styles['color-picker']}>
									{pencilWidth?.map((width, index) => {
										return (
											<div
												className={styles['position']}
												style={{
													marginTop: `-${30 * `${openPencilWidth ? 0 : index}`}px`,
													zIndex: `${width === currentPencil ? 1000 : 1}`,
												}}
												onClick={() => handlePencilWidth(width)}
												key={index}>
												<div
													className={styles['pencilWidth-picker']}
													style={{
														opacity: index === 0 ? 1 : undefined,
													}}>
													<div className={styles['innerPencilWidth']} style={{ width: `${4 * (index + 1)}px`, height: `${4 * (index + 1)}px` }}></div>
												</div>
											</div>
										);
									})}
									.
								</div>
								<div className={styles['color-picker']}>
									{colors?.map((color, index) => {
										return (
											<div
												className={styles['position']}
												style={{
													marginTop: `-${30 * `${openColorPallet ? 0 : index}`}px`,
													zIndex: `${color === currentColor ? 1000 : 1}`,
												}}
												key={index}>
												<div
													className={styles['rounded-color-picker']}
													style={{
														background: color,
														border: color === 'white' ? '1px solid black' : undefined,
														opacity: index === 0 ? 1 : undefined,
													}}
													onClick={() => handleColorPallet(color)}></div>
											</div>
										);
									})}
								</div>

								<div className={styles['con-slide']}>
									<div className={styles['con-slide-icon']}>
										<div className={`${styles['slide-icon']} ${classNames((slidePosition === 'left') | (slidePosition === '') && styles['active'])}`} onClick={activateLeft}>
											<PencilSvg />
										</div>

										<div className={`${styles['slide-icon']} ${classNames(slidePosition === 'right' && styles['active'])}`} onClick={activateRight}>
											<TextSvg />
										</div>
									</div>
									<div className={`${styles['slide']} ${styles[slidePosition]}`}></div>
								</div>
							</div>

							<div className="flex">
								<MediumButton buttonContent={active ? 'sending to david...' : 'send it to david'} onclick={handleSubmit} disabled={active} />
								<button className={styles['clear-button']} onClick={clearCanvas}>
									clear
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className={`${styles['connector']} ${styles['first-connector']}`}></div>
				<div className={`${styles['connector']} ${styles['second-connector']}`}></div>
				<div className={`${styles['connector']} ${styles['third-connector']}`}></div>
				<div className={`${styles['connector']} ${styles['fourth-connector']}`}></div>
			</div>
		</>
	);
}

export default Cuboid;
